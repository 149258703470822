import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Divider } from '@material-ui/core';
import { Topbar, Footer, Sidebar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}));

const Main = ({ children, themeToggler, themeMode }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const pages = {
    landings: {
      title: 'Sell',
      id: 'landing-pages',
      children: {
        services: {
          groupTitle: 'Online Shop',
          pages: [
            {
              title: 'Ecommerce',
              href: '/e-commerce',
              subTitle: 'Get your online store up and running in minutes ',
            },
            {
              title: 'Payments',
              href: '/payments',
              subTitle: 'Many payment systems available out of the box',
            },
            {
              title: 'Features',
              href: '/features',
              subTitle:
                "Explore many features avaible to your business' growth",
            },
          ],
        },
        web: {
          groupTitle: 'In-Person Shop',
          pages: [
            {
              title: 'Point of Sale',
              href: '/pos',
              subTitle: 'Sell with your smartphone or a PoS device',
            },
            {
              title: 'Mobile App',
              href: '/mobile-app',
              subTitle:
                'Download the mobile app on your smartphone and start selling',
            },
          ],
        },
      },
    },

    grow: {
      title: 'Grow',
      id: 'grow',
      children: {
        services: {
          groupTitle: 'Sell',
          pages: [
            {
              title: 'Online',
              href: '/coworking',
              subTitle: 'Really testing the multi-shop ',
            },
          ],
        },
        apps: {
          groupTitle: ' ',
          pages: [],
        },
        web: {
          groupTitle: 'Web',
          pages: [
            {
              title: 'Marketing',
              href: '/',
              subTitle: 'Really testing the multi-shop',
            },
          ],
        },
      },
    },
    pages: {
      title: 'Grow',
      id: 'supported-pages',
      children: {
        career: {
          groupTitle: 'Marketing',
          pages: [
            {
              title: 'Digital Marketing',
              href: '/marketing',
              subTitle:
                'Promote your business on Google, Facebook, Instagram, Linkedin with integrated maketing apps',
            },
          ],
        },
        helpCenter: {
          groupTitle: ' ',
          pages: [
            // {
            //   title: 'Overview',
            //   href: '/help-center',
            // },
            // {
            //   title: 'Article',
            //   href: '/help-center-article',
            // },
          ],
        },
        company: {
          groupTitle: 'Customization',
          pages: [
            {
              title: 'Customize and Extend',
              href: '/customize',
              subTitle:
                'Bring your brand alive. Customize and extend your ecommerce website',
            },
          ],
        },
        contact: {
          groupTitle: ' ',
          pages: [],
        },
      },
    },
    // account: {
    //   title: 'Resources',
    //   id: 'account',
    //   children: {
    //     settings: {
    //       groupTitle: 'DEVELOPER',
    //       pages: [
    //         {
    //           title: 'API Documentation',
    //           href: '/apidoc',
    //           subTitle: 'Extend Vend.One with APIs ',
    //         },
    //         {
    //           title: 'Webhook',
    //           href: '/apidoc',
    //           subTitle: 'Extend Vend.One with easy to use webhook',
    //         },
    //       ],
    //     },
    //     signup: {
    //       groupTitle: 'Company',
    //       pages: [
    //         {
    //           title: 'About',
    //           href: '/about',
    //           subTitle: 'Learn about who we are and what we stand for ',
    //         },
    //         {
    //           title: 'Pricing',
    //           href: '/pricing',
    //           subTitle: 'Affordable pricing that fits your budget ',
    //         },
    //         {
    //           title: 'Career',
    //           href: '/career',
    //           subTitle: 'We want to work with you ',
    //         },
    //       ],
    //     },
    //     signin: {
    //       groupTitle: ' ',
    //       pages: [],
    //     },
    //     password: {
    //       groupTitle: 'Support',
    //       pages: [
    //         {
    //           title: 'Learning',
    //           href: 'https://learn.vend.one',
    //           subTitle: 'A learning platform to get you started ',
    //         },
    //         {
    //           title: 'Help Center',
    //           href: '/help-center',
    //           subTitle: 'A community support ',
    //         },
    //         {
    //           title: 'Contact',
    //           href: '/contact-page',
    //           subTitle: 'We want to hear from you ',
    //         },
    //       ],
    //     },
    //     error: {
    //       groupTitle: ' ',
    //       pages: [],
    //     },
    //   },
    // },
  };

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar
        onSidebarOpen={handleSidebarOpen}
        pages={pages}
        themeMode={themeMode}
        themeToggler={themeToggler}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        <Divider />
        {children}
      </main>
      <Footer pages={pages} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Main;
